import { Routes } from '@shared/enums';

export const signUpIntroData = [
  {
    icon: 'volunteer-circle',
    title: 'want-to-help',
    cardBgColorCn: 'bg-solitude',
    to: Routes.VolunteerSignUp,
  },
  {
    icon: 'in-need-circle',
    title: 'get-help',
    cardBgColorCn: 'bg-beige',
    to: Routes.CustomerSignUp,
  },
] as const;
