import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button } from '@shared/ui/buttons/Button';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

interface SignUpIntroOptionProps {
  icon: any;
  title: string;
  cardBgColorCn: string;
  to: string;
}

function useComponentState(props: SignUpIntroOptionProps) {
  return {
    ...props,
  };
}

export const SignUpIntroOption: React.FC<SignUpIntroOptionProps> = (props) => {
  const { icon, to, title, cardBgColorCn } = useComponentState(props);
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'relative w-full max-w-[328px] p-6 pt-12 rounded-lg md:max-w-[248px] md:pt-[60px] md:pb-8',
        cardBgColorCn,
      )}
    >
      <GatsbyImage
        image={getImage(icon)}
        alt="icon"
        className="!absolute left-1/2 -top-10 transform -translate-x-1/2 w-20 h-auto"
      />
      <span className="block text-center">{t(title)}</span>
      <div className=" flex justify-center mt-4">
        <Button as="link-in" size="small" to={to}>
          {t('select')}
        </Button>
      </div>
    </div>
  );
};
