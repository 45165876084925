import { ApplicantType } from '@shared/enums';
import React, { createContext, useContext, useState } from 'react';

interface ISignUpPersonContext {
  applicantType: ApplicantType;
  setApplicantType: (key: ApplicantType) => void;
  step: number;
  setStep: (step: number) => void;
  phoneNumber: string | null;
  setPhoneNumber: (key: string | undefined | null) => void;
}

const SignUpPersonContext = createContext({} as ISignUpPersonContext);
export const useSignUpPersonContext = (): ISignUpPersonContext => useContext(SignUpPersonContext);

export const SignUpPersonProvider: React.FC = ({ children }) => {
  const [applicantType, setApplicantType] = useState<ApplicantType>(ApplicantType.HumanBeing);
  const [step, setStep] = useState<number>(1);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  const value = {
    applicantType,
    setApplicantType,
    step,
    setStep,
    phoneNumber,
    setPhoneNumber,
  };

  return <SignUpPersonContext.Provider value={value}>{children}</SignUpPersonContext.Provider>;
};
