import React, { useMemo } from 'react';
import { useLanguage } from '@hooks/useLanguage';
import { signUpIntroData } from '@values/sign-up-intro-variants';
import { useTranslation } from 'react-i18next';
import { SignUpAlreadyRegistered } from '../SignUpAlreadyRegistered';
import { SignUpIntroOption } from './SignUpIntroOption';
import { useGetIconsOptimized } from '@shared/hooks/useGetIconsOptimized';

export const SignUpIntro = () => {
  const language = useLanguage();
  const { t } = useTranslation();
  const imagesOprimized = useGetIconsOptimized();
  const signUpIntroWithImages = useMemo(() => {
    return signUpIntroData.map((item) => {
      const iconOptimized = imagesOprimized.find((v) => v.name === item.icon);

      return {
        ...item,
        icon: iconOptimized.content,
      };
    });
  }, [imagesOprimized]);

  const filteredSignUpIntroOptions =
    language === 'uk' ? signUpIntroWithImages : signUpIntroWithImages.filter((v) => v.title === 'want-to-help');

  return (
    <div className="mt-20 md:max-w-[808px] md:mx-auto md:mt-10 md:px-6 md:pt-12 md:pb-28 md:rounded-lg md:border-2 md:border-hawkes-blue md:bg-white">
      <h4 className="hidden md:block text-lg text-center">{t('choose-what-you-need')}</h4>
      <div className="flex flex-col items-center space-y-14 md:flex-row md:mt-[88px] md:space-x-8 md:space-y-0 md:justify-center">
        {filteredSignUpIntroOptions.map((item) => (
          <SignUpIntroOption key={item.to} {...item} />
        ))}
      </div>
      <SignUpAlreadyRegistered className="mt-10" />
    </div>
  );
};
