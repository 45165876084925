import React from 'react';

import { SignUpIntro } from '@widgets/signUp/signUpIntro/SignUpIntro';
import { SignUpRegisterTitle } from '@widgets/signUp/SignUpRegisterTitle';

const SignUpPage = () => {
  return (
    <div className="px-4 pt-6 pb-12 lg:pt-12">
      <SignUpRegisterTitle />
      <SignUpIntro />
    </div>
  );
};

export default SignUpPage;
